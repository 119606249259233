var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"my-sell-offers-list"},[(_vm.modalOpen)?_c('Modal',{staticClass:"my-sell-offers-list__modal",attrs:{"active":_vm.modalOpen,"onClose":() => _vm.toggleModal(null),"name":"my-sell-offers-list-delete"}},[_c('p',[_vm._v(_vm._s(_vm.$t("mySellOffer.delete.title")))]),_c('template',{slot:"footer"},[_c('button',{staticClass:"my-sell-offers-list__cancel-btn light",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){_vm.modalOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("mySellOffer.delete.cancelButtonText"))+" ")]),_c('button',{staticClass:"my-sell-offers-list__submit-btn",attrs:{"type":"primary","disabled":_vm.isLoading},on:{"click":_vm.onDeleteSubmit}},[_vm._v(" "+_vm._s(_vm.$t("mySellOffer.delete.confirmButtonText"))+" ")])])],2):_vm._e(),_c('div',{staticClass:"section-label"},[_vm._v(" "+_vm._s(_vm.$t("mySellOffer.pageTitle")))]),(_vm.mySellOffers)?_c('ExporoTable',{staticClass:"my-sell-offers-list__table",attrs:{"data":_vm.tableData,"isLoading":false}},[_vm._l((_vm.tableData),function(row){return _c('template',{attrs:{"slot-scope":row},slot:`${
        row[
          _vm.$t('mySellOffer.table.header', {
            context: 'actions',
          })
        ].slotId
      }`},[_c('ExporoTooltip',{key:`tooltip-${
          row[
            _vm.$t('mySellOffer.table.header', {
              context: 'actions',
            })
          ].slotId
        }`,scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"my-sell-offers-list__delete btn light error circle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.toggleModal(
                row[_vm.$t('mySellOffer.table.header', { context: 'id' })].value
              )}}},[_c('i',{staticClass:"material-icons"},[_vm._v("delete_forever")])])]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("mySellOffer.delete.tooltip"))+" ")]},proxy:true}],null,true)})],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }