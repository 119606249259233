
import { Component, Vue } from 'vue-property-decorator';
import TradingList from '@/components/depot/TradingList.vue';
import MySellOffersList from '@/components/trading/my-selloffer/MySellOffersList.vue';
import { Action, Getter } from 'vuex-class';
import { Asset } from '@/store/trading/types';

@Component({
  name: 'trading-list-view',
  components: {
    TradingList,
    MySellOffersList,
  },
})
export default class TradingListView extends Vue {
  @Getter('trading/projects') projects?: Asset[];
  @Getter('trading/loading') loading?: Boolean;
}
