
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { centsToEuro } from '@/filters/currency';
import { MySellOffer } from '@/store/trading/types';
import { BaaderUser } from '@/store/profile/types';
import ExporoTable from '@/components/_common/Table.vue';
import ExporoTooltip from '@/components/_common/Tooltip.vue';
import Modal from '@/components/_common/Modal.vue';

@Component({
  name: 'my-sell-offers-list',
  filters: {
    centsToEuro,
  },
  components: {
    ExporoTable,
    ExporoTooltip,
    Modal,
  },
})
export default class MySellOffersList extends Vue {
  @Action('appState/startLoader') startLoader;
  @Action('appState/stopLoader') stopLoader;
  @Action('trading/cancelSellOffer') cancelSellOffer;
  @Action('trading/fetchMySellOffers') fetchMySellOffers;

  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('trading/mySellOffers') mySellOffersGetter;
  @Getter('profile/baaderUser') baaderUser?: BaaderUser;

  private mySellOffers: MySellOffer[] = null;
  private modalOpen: boolean = false;
  private sellfOfferId: string = null;

  get tableData(): Array<object> {
    if (this.mySellOffers) {
      return this.mySellOffers
        .map((sellOffer, index) => ({
          [ this.$t('mySellOffer.table.header', { context: 'id' }) ]: {
            text: sellOffer.id,
            value: sellOffer.id,
          },
          [ this.$t('mySellOffer.table.header', { context: 'contractTitle' }) ]: {
            text: sellOffer.asset.title,
            value: sellOffer.asset.title,
          },
          [ this.$t('mySellOffer.table.header', { context: 'initialMaxPieces' }) ]: {
            text: sellOffer.initial_max_pieces,
            value: sellOffer.initial_max_pieces,
          },
          [ this.$t('mySellOffer.table.header', { context: 'maxPieces' }) ]: {
            text: sellOffer.available,
            value: sellOffer.available,
          },
          [ this.$t('mySellOffer.table.header', { context: 'unitPrice' }) ]: {
            text: this.$options.filters.centsToEuro(sellOffer.unit_price),
            value: sellOffer.unit_price,
          },
          [ this.$t('mySellOffer.table.header', { context: 'total' }) ]: {
            text: this.$options.filters.centsToEuro(sellOffer.total),
            value: sellOffer.total,
          },
          [ this.$t('mySellOffer.table.header', { context: 'state' }) ]: {
            text: this.$t(`mySellOffer.states.${sellOffer.state}`) || sellOffer.state,
            value: this.$t(`mySellOffer.states.${sellOffer.state}`) || sellOffer.state,
          },
          [ this.$t('mySellOffer.table.header', { context: 'total' }) ]: {
            text: this.$options.filters.centsToEuro(sellOffer.total),
            value: sellOffer.total,
          },
          [ this.$t('mySellOffer.table.header', { context: 'actions' }) ]: {
            text: sellOffer.id,
            value: sellOffer.id,
            slotId: `actions-${sellOffer.id}`,
          },
        }));
    }

    return [];
  }

  mounted() {
    this.getMySellOffers();
  }

  async getMySellOffers() {
    this.modalOpen = false;

    try {
      this.startLoader();
      await this.fetchMySellOffers();

      this.mySellOffers = this.mySellOffersGetter
        .filter((sellOffer) => sellOffer.user_id == this.baaderUser.user_id && sellOffer.brand === 'exporo')
        .sort((a,b) => a.updated_at < b.updated_at ? 1 : -1);

      this.stopLoader();
    } catch (error) {
      console.error(error);
      this.stopLoader();
    }
  }

  async onDeleteSubmit() {
    try {
      this.startLoader();
      await this.cancelSellOffer(this.sellfOfferId);
      this.getMySellOffers();
    } catch (error) {
      console.error(error);
      this.modalOpen = false;
      this.stopLoader();
    }
  }

  toggleModal(sellfOfferId: string | null) {
    this.modalOpen = !this.modalOpen;
    this.sellfOfferId = sellfOfferId;
  }
};
